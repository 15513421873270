import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Hero from "@components/hero";
import PageSeo from "@components/SEO";
import PanelComponent from "@components/panel";

const Page = () => {
  const homepage = useStaticQuery(
    graphql`
      query homepage {
        wpPage(uri: {eq: "/"}) {
          uri
          id
          title
          seo {
            title
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
            opengraphTitle
            opengraphDescription
            opengraphType
            opengraphUrl
            twitterTitle
            twitterDescription
            twitterImage {
              uri
            }
          }
        }
        wp {
          id
          themeGeneralSettings {
            settingsThemeSettings {
              content
              fieldGroupName
              homepageButtonLabel
              pageHeading
              pageTitle
            }
          }
        }
      }
    `
  );

  const seo = homepage.wpPage?.seo;
  const content = homepage.wp?.themeGeneralSettings?.settingsThemeSettings;

  const defaults = {
    longTitle: content.pageTitle ?? "Page not found",
    title: content.pageHeading ?? "404",
    description: content.content ?? "<p>Sorry, you’ve got off at the wrong stop! The page you are looking for can’t be found.</p>",
    buttonLabel: content.homepageButtonLabel ?? "Back to homepage"
  }

  const call_to_actions = [
    {
      link: {
        url: "/",
        label: defaults.buttonLabel,
        target: ""
      }
    }
  ]


  return (
    <>
      {seo 
        && <PageSeo {...seo} 
              title={defaults.longTitle}
              opengraphTitle={defaults.longTitle}
              twitterTitle={defaults.longTitle}
              metaDesc={defaults.description}
              opengraphDescription={defaults.description}
              twitterDescription={defaults.description}
              metaRobotsNofollow={"nofollow"}
              metaRobotsNoindex={"noindex"}
           />
      }
      <Hero 
        heading={defaults.title} 
        content={defaults.description}
        callToActions={call_to_actions}  
      />
      <PanelComponent variant="white" isDirectChild={1}>
        <p>&nbsp;</p>
      </PanelComponent>
    </>
  );
};

export default Page;
